// Copyright (C) 2023, Rutio AB. All rights reserved

import { useEffect, useState } from 'react';
import { Fetch } from './Fetch';
import { getApi } from './api';
import { alarmColor, deviceColor, checkColor, selectColor, smallFontSize, smallButtonStyle, smallTextStyle, checkpointColor } from './styling';
import { calculateDistance } from './trigonometrics';
import { formatAge, formatDateAsAge } from './dateFormat';
import { ClickOnceButton } from './ClickOnceButton';
import { isBatteryLow } from './isBatteryLow';
import { getMaxAlarmTimeS } from './limits';

const ec = (s) => encodeURIComponent(s);

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const showAsAlarm = item => {
  const now = new Date();
  if ( item && item.checkinTime )
    return false; // Item is checked
  if ( item && item.alarmTime) {
    const ageS = (now.getTime() - new Date(item.alarmTime).getTime())/1000;
    return ageS < getMaxAlarmTimeS() ? true : false;
  }
  return false;
}

const showAsChecked = item => {
  if (item && item.checkinTime)
    return true;
  return false;
}

const nibbleToHex = (x) => {
  const table="0123456789abcdef"
  x &= 0xf;
  return table[x];
}

const addAgeTransparency = (maxAge, item, color, multi) => {
  if (typeof(color) !== "string" || color.length !== 4)
    return color;
  if (!(item && item.positionTime))
    return color;
  if (multi)
    return color;
  const nowS = new Date().getTime()/1000;
  const itemS = new Date(item.positionTime).getTime()/1000;
  const age = nowS-itemS;
  if (age >= maxAge)
    return color + "c";
  let fraction = Math.floor(12*age/maxAge);
  let opacity = nibbleToHex(0xf-fraction);

  return color + opacity;
}

const getDeviceColor = (device) => {
  if (showAsAlarm(device))
    return alarmColor;
  if (device.checkpoint)
    return checkpointColor;
  if (showAsChecked(device))
    return checkColor;
  return deviceColor;
}

const SideMarker = (props) => {
  const [expanded, setExpanded] = useState(false);
  const [itemsOnSamePosition] = useState(props.itemsOnSamePosition);
  const [alarmOnSamePosition] = useState(props.alarmOnSamePosition);
  const [imageScale] = useState(props.imageScale);
  const [width] = useState(props.width);
  const [height] = useState(props.height);
  const [maxAge] = useState(props.maxAge);
  const [count] = useState(props.itemsOnSamePosition ? props.itemsOnSamePosition.length : 1);
  const [posTS] = useState(props.item.positionTime);
  const [itemTS] = useState(props.item.timestamp);
  const [maxAgePct] = useState(props.item.position)
  const [alarmTime] = useState(props.item.alarmTime);
  const [hash] = useState("" + posTS + itemTS + maxAgePct + alarmTime + count);
  const [size] = useState(props.metadata && props.metadata.marker_size ? props.metadata.marker_size*imageScale : imageScale*15);
  const [xy] = useState("" + props.xpos + "," + props.ypos);
  const [scroll, setScroll] = useState(0);
  const [touchY, setTouchY] = useState(0);

  let checkpointCount = 0;
  
  let k;
  if (itemsOnSamePosition) {
    const arr = itemsOnSamePosition.map(i=>{return "" + (i.item.checkinTime?"C":"-") + (i.item.alarmTime?"A":"")});
    k = arr.join("");
    itemsOnSamePosition.map(i=>checkpointCount+=i.item.checkpoint ? 1: 0);
  }
  else
    k = (props.item.alarmTime?"A":"-")+(props.item.checkinTime?"C":"-");

  const onScrollDelta = (delta) => {
    if (scroll + delta/10<=0)
      setScroll(0);
    else if (scroll+delta/10>=itemsOnSamePosition.length-10 && itemsOnSamePosition.length>10) 
      setScroll(itemsOnSamePosition.length-10);
    else if (itemsOnSamePosition.length>10)
      setScroll(scroll+delta/10);    
  }

  if (expanded) {
    let leftRightStyle = {left:(props.xpos-size/2)+"px", };
    let topBottomStyle = {bottom:(height-props.ypos-size/2)+"px"}
    if (props.xpos>width/2) 
      leftRightStyle = {right:(width-(props.xpos+size/2))+"px"}
    if (props.ypos<height/2)
      topBottomStyle = {top:(props.ypos-size/2)+"px"}
    return <div key={k} style={{zIndex:2, position:'fixed', top: 0, left:0, height:"100%", width:"100%", background:"#fffc"}} onClick={()=>setExpanded(false)}>
    <div style={{position:"fixed", touchAction:"none", ...topBottomStyle, ...leftRightStyle, background:"#eee6", borderStyle:"solid", padding:"2px", borderWidth:"1px", borderRadius:"5px"}} 
                onClick={(e)=>{e.stopPropagation(); props.setPopped(null); setExpanded(false); setScroll(0);}}
                onWheel={(e)=>{onScrollDelta(e.deltaY); }}
                  onTouchStart={(e)=>setTouchY(e.touches[e.touches.length-1].screenY)}
                  onTouchMove={(e)=>{
                    let delta = (touchY-e.touches[e.touches.length-1].screenY);
                    onScrollDelta(delta/2);
                    setTouchY(e.touches[e.touches.length-1].screenY);
                  }}
                  >
      <div style={{width:"30vw", fontSize:size-2}}>Showing {Math.floor(scroll)+1}-{Math.min(Math.floor(scroll+10), itemsOnSamePosition.length)} of {itemsOnSamePosition.length} at position</div>
      <hr style={{color:"#cccc"}}/>
      {itemsOnSamePosition.map(
        (i,ix)=>(ix >= Math.floor(scroll) && ix < Math.floor(scroll)+10) ? <div key={height+i.item.id} style={{display:"flex", flexDirection:"row", zIndex:2000, fontSize:size-2}} 
                  onClick={(e)=>{e.stopPropagation(); setExpanded(false); setScroll(0); props.setPopped({item:i.item, y:props.ypos, x:props.xpos})}}>
                <div
                  style={{ width: size+"px", height: size+"px", margin:"1px", borderRadius: ".5vmin", borderWidth: "1px", borderStyle: "solid", borderColor: "white", 
                  background: addAgeTransparency(maxAge, i.item, getDeviceColor(i.item)) }}></div>{i.item.title+ (i.item.checkpoint ? "" : (" (" + formatDateAsAge(new Date(i.item.positionTime)) + ")"))} </div> : null)}
      </div>
    </div>
  }

  return <div onClick={(e)=>{
    e.stopPropagation(); 
    if (count === 1) {
      props.setPopped({item:props.item, y:props.ypos, x:props.xpos})
    } else {
      props.setPopped(null);
      setExpanded(!expanded);
      setScroll(0);
    }
    }}>
      {props.item.checkpoint ? <div key={"chk"+k} style={{ 
      position: "fixed", top: (props.ypos-3-size/2)+"px", left: (props.xpos-3-size/2)+"px", zIndex:0, width: (size+2)+"px", height: (size+2)+"px", borderRadius: "1vmin", borderWidth: "3px", borderStyle: "solid", borderColor: checkpointColor, 
                background: "#0000"}}></div> : null}    
    <div style={{ position: "fixed", top: (props.ypos-size/2)+"px", left: (props.xpos-size/2)+"px", textSize: smallFontSize }} 
              key={"" + k + height + props.item.id  + count}
              >
    <div key={"label"+k} style={{ zIndex:0, position:"fixed", top: (props.ypos+size/2)+"px", left: (props.xpos-size/2)+"px" ,  
                fontSize:2*size/3, textAlign:"left", fontWeight:600, color:"#000",
                background: "#fff9" }}>{itemsOnSamePosition.map(i=>showAsAlarm(i.item) ? <div style={{color: alarmColor}}>{i.item.title + " (" + formatDateAsAge(new Date(i.item.positionTime)) + ")"}</div> : null)}</div>
    <div key={"box"+k} style={{ zIndex:1, width: size+"px", height: size+"px", borderRadius: ".6vmin", borderWidth: "1px", borderStyle: "solid", borderColor: "white", 
                fontSize:size-2, fontWeight:600, color:"white",
                background: addAgeTransparency(maxAge, props.item, props.selected === props.item.id ? selectColor : ((showAsAlarm(props.item) || alarmOnSamePosition)? alarmColor:(showAsChecked(props.item) ? checkColor:(props.item.checkpoint?checkpointColor:deviceColor))), count > 1 || alarmOnSamePosition) }}>{count > 1 ? count-checkpointCount : ""}</div>
  </div>
  </div>
}

let seq = 0;

const generateItem = (metadata, item, imageScale, imageTop, setPopped, levelItems, width, height, selected, setSelected, maxAge) => {

  // Calculate y coordinate
  let x = metadata.x_left * imageScale;
  let y = imageTop;
  if (typeof (metadata.floor_index_y_mapping) === "object"
    && metadata.floor_index_y_mapping.hasOwnProperty(item.floor)) {
    y += metadata.floor_index_y_mapping[item.floor] * imageScale;
  } else {
    if (item.floor === undefined || item.floor === '')
      return <div onClick={()=>{window.localStorage.setItem("showAccuracy", "true");setSelected(item.id);}}><small>{item.title ? item.title:item.text}: Floor not set</small></div>

    return <div>{item.title ? item.title: item.text}: Floor '{item.floor}' not found</div>
  }

  // Calculate x coordinate (based on distance)
  let itemlat, itemlng;
  if (item.latlng && Array.isArray(item.latlng) && item.latlng.length>=2) {
    itemlat = item.latlng[0];
    itemlng = item.latlng[1];
  } else {
    return <div><small>{item.title ? item.title : item.text}: No position set</small></div>
  }

  let valid = true;
  // Do triangle solution
  const a = calculateDistance(metadata.reference_lat_left, metadata.reference_lon_left, metadata.reference_lat_right, metadata.reference_lon_right);
  const b = calculateDistance(itemlat, itemlng, metadata.reference_lat_left, metadata.reference_lon_left);
  const c = calculateDistance(itemlat, itemlng, metadata.reference_lat_right, metadata.reference_lon_right);
  // console.log("a,b,c=", a,b,c);
  if (b>a || c>a) {
    valid = false;
  }

  // Herons formula, calculates triangle area
  const s = (a+b+c)/2;
  const A = Math.sqrt(s*(s-a)*(s-b)*(s-c));
  const h = 2*A/a;
  // console.log("A, h, a", A, h, a);
  if (h > a)
    valid = false;

  // Pythagoras to calculate position on boat
  const distanceL = Math.sqrt(b*b - h*h);
  // console.log("distanceL", distanceL);

  // Calculate distance from left corner latlng
  // const distanceL = calculateDistance(itemlat, itemlng, metadata.reference_lat_left, metadata.reference_lon_left);
  if (valid)
    valid = distanceL >= 0 && distanceL <= (metadata.actual_length_m + 2);
  // Calculate distance from right corner latlng
  //const distanceR = calculateDistance(itemlat, itemlng, metadata.reference_lat_right, metadata.reference_lon_right);
  //const RValid = distanceR <= metadata.actual_length_m + 2;

  if (!valid) {
    // console.log("Position for " + item.title + " is invalid, distanceL = " + distanceL + "(max " + metadata.actual_length_m + ")");
    if (showAsAlarm(item) )
      return <div onClick={()=>{setSelected(item.id); window.localStorage.setItem("showAccuracy", "true")}}><small>{item.title ? item.title: item.text} not on site (Trace to check)</small></div>
    else
      return null;
  }

  const distanceLUse = distanceL;
/*
  let distanceLUse = 0;
  if (LValid && !RValid)
    distanceLUse =  distanceL;
  else if (RValid && !LValid)
    distanceLUse = metadata.actual_length_m - distanceR;
  else { // Use the longest of the two
    distanceLUse = distanceL > distanceR ? distanceL : (metadata.actual_length_m - distanceR);
  }
  */

  // How many pixels are actual width representing
  const fullXDistancePixels = (metadata.x_right - metadata.x_left)*imageScale;
  let xfraction = distanceLUse/metadata.actual_length_m;
  if (xfraction > 1) {
    xfraction = 1;
    console.log("Fraction trunkated: " + distanceLUse);
  }
  if (xfraction < 0) {
    console.log("Fraction trunkated: " + distanceLUse);
    xfraction = 0;
  }
  x += xfraction* fullXDistancePixels;

  // Metadata may have limits per floor
  if (metadata.x_left_limits && metadata.x_left_limits[item.floor] && x < metadata.x_left_limits[item.floor]*imageScale) {
    x = metadata.x_left_limits[item.floor]*imageScale;
  }
  if (metadata.x_right_limits && metadata.x_right_limits[item.floor] && x > metadata.x_right_limits[item.floor]*imageScale) {
    x = metadata.x_right_limits[item.floor]*imageScale;
  }

  let itemsOnSamePosition = []; // Itself
  let alarmOnSamePosition = false;
  if (levelItems.hasOwnProperty(y)) {
    // Check if multiple items at this position
    for (let i = 0; i < levelItems[y].length; ++i) {
      const itemInfo = levelItems[y][i];
      if (itemInfo.x > x+metadata.marker_size*imageScale || itemInfo.x < x-metadata.marker_size*imageScale)
        continue;
      x = itemInfo.x;
      if (showAsAlarm(itemInfo.item))
        alarmOnSamePosition = true;
      itemsOnSamePosition.push(itemInfo);
    }
    // Add this item
    levelItems[y].push({x,y,item});
  }
  else
    levelItems[y] = [{x,y,item}]

  itemsOnSamePosition.push({x,y,item})

  let hash = "";
  itemsOnSamePosition.map(i=>{hash+=i.item.alarmTime?"A":"-"; hash+=i.item.checkinTime?"C":"-"});

  return <SideMarker key={hash+height+item.id+itemsOnSamePosition.length+maxAge + x} item={item} metadata={metadata} xpos={x} ypos={y} selected={item.selected} setPopped={setPopped} 
            imageScale={imageScale} width={width} height={height} maxAge={maxAge}
            alarmOnSamePosition={alarmOnSamePosition} itemsOnSamePosition={itemsOnSamePosition} />
}

const Popped = ({popped, setPopped, width, height, markerSize, setSelectedId, uncheckItem, setUncheckItem, 
                 checkItem, setCheckItem, currentCheckpoint, setCurrentCheckpoint, isChecker, isAdmin}) => {
  const color = getDeviceColor(popped.item);
  const [state, setState] = useState(JSON.stringify({popped, width, height})); // Will make state change => redraw if props change?
  const arrowSize = 20;
  useEffect(()=>{
    const timer = setTimeout(() => setPopped(null), 15000);
    return () => clearTimeout(timer);
  }, []);

  let leftRightStyle = {left:popped.x+"px", };
  let topBottomStyle = {bottom:(height-popped.y)+"px"}
  if (popped.x>width/2) 
    leftRightStyle = {right:(width-popped.x)+"px"}
  if (popped.y<height/2)
    topBottomStyle = {top:(popped.y)+"px"}

  const displayAccuracy = false;
  
  const body = <div 
    key={state}
    onClick={()=>setPopped(null)} 
    style={{fontSize: smallFontSize, 
    position:"fixed", 
    minWidth:2*arrowSize,
    ...topBottomStyle,
    ...leftRightStyle,
    background:color, 
    color:"white",
    borderRadius:"8px", 
    padding:"6px"}}>
  {popped.item.checkpoint ? <small>{currentCheckpoint===popped.item.id?"CURRENT ":""}CHECKPOINT<br/></small> : null}
  {popped.item.checkinTime ? <small>CHECKED AT CHECKPOINT<br/></small> : null}
  <div style={{fontWeight:600}}>{popped.item.title}</div>
  <small>{popped.item.text}</small><br/>
  <hr/>
  {popped.item.alarmTime ? "Alarm age: "+ formatDateAsAge(new Date(popped.item.alarmTime)) : null}<br/>
  {popped.item.timestamp ? "Signal age: "+ formatDateAsAge(new Date(popped.item.timestamp)) : null}<br/>
  {(popped.item.positionTime && !popped.item.checkpoint) ? "Position age: "+ formatDateAsAge(new Date(popped.item.positionTime)) : null}<br/>
  {popped.item.checkinTime ? "Checkin age: " + formatDateAsAge(new Date(popped.item.checkinTime)) : null}<br/>
  {popped.item.output && popped.item.output.volts ? <small>Battery: {isAdmin ? ((Math.round(popped.item.output.volts * 10) / 10)+"V "): ""}{ isBatteryLow(popped.item) ? "LOW" : "OK"}</small> : null}
  {!popped.item.checkpoint ? <hr/> : null}
  {!popped.item.checkpoint ? <small>Floor {popped.item.floor} {displayAccuracy ? "- Accuracy " + popped.item.accuracy + " m" : ""}</small>:null}
  {popped.item.positionTime && setSelectedId ? <hr/> : null}
  {isAdmin && popped.item.positionTime && setSelectedId ? <ClickOnceButton text="Trace" onClick={()=>{window.localStorage.setItem("showAccuracy", "false");setSelectedId(popped.item.id);}}/> : null}
  {isChecker && popped.item.checkinTime && (!uncheckItem) ? <ClickOnceButton text="Uncheck" onClick={(e)=>{setUncheckItem(popped.item.id)}}/> : null}
  {isChecker && currentCheckpoint && setCurrentCheckpoint && !popped.item.checkpoint ? <ClickOnceButton text="Check in" onClick={(e)=>{setCheckItem(popped.item.id)}}/> : null}
  {isChecker && popped.item.checkpoint && currentCheckpoint !== popped.item.id ? <ClickOnceButton text="Make Current" onClick={(e)=>{setCurrentCheckpoint(popped.item.id)}}/> : null}
  {isChecker && popped.item.checkpoint && currentCheckpoint === popped.item.id ? <ClickOnceButton text="Clear Current" onClick={(e)=>{setCurrentCheckpoint(null)}}/> : null}
  </div>

  return <div> 
    {body}
  </div>;
}

export const SideWays = (props) => {
  const [metadata, setMetadata] = useState(null);
  const [popped, setPopped] = useState(null);
  const [uncheckItem, setUncheckItem] = useState(null);
  const [checkItem, setCheckItem] = useState(null);

  const {series, isChecker, isAdmin, setSelected, currentCheckpoint, setCurrentCheckpoint} = props;
  const [selected] = useState(props.selected);
  const [maxAge] = useState(props.maxAge);
  const [tracing] = useState(props.tracing);
  let count = 0;

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    const ignore = e => e.preventDefault();

    window.addEventListener('dragstart', ignore, {passive:false});
    window.addEventListener('wheel', ignore, {passive:false});
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      window.removeEventListener('wheel', ignore);
      window.removeEventListener('dragstart', ignore);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  const width = windowDimensions ? windowDimensions.width : 100;
  const height = windowDimensions ? windowDimensions.height : 80;

  let fetcher = null;
  if (!metadata)
    fetcher = <Fetch key={"fetch-metadata"}
      submit={true}
      onError={(e) => { setMetadata({ error: "Failed to load metadata" }) }}
      onResult={(r) => {
        console.log("Received metadata: ", r);
        setMetadata(r);
      }}
      api={getApi() + `/sidewaysdata?token=${ec(window.localStorage.getItem('token'))}`} />;

  if (fetcher) {
    return <div style={{smallTextStyle}}>{fetcher}Loading...</div>
  }
  if (metadata && metadata.error) {
    return <div>{metadata.error}</div>
  }

  if (!metadata || !metadata.image_w) {
    return <div>Failed to load metadata</div>
  }
  console.log("Window dimenstions (w,h): ", width, height);
  const imageScale = width / metadata.image_w * 1.0;
  const imageTop = height / 2 - imageScale * metadata.image_h / 2;

  const referenceDistance = calculateDistance(metadata.reference_lat_left, metadata.reference_lon_left,
                                              metadata.reference_lat_right, metadata.reference_lon_right);
  let warning = null;
  if (referenceDistance > 1.1*metadata.actual_length_m || referenceDistance < 0.9*metadata.actual_length_m) {
//    warning = <div style={{fontSize:smallFontSize}}>Warning: The displayed object is {Math.round(metadata.actual_length_m)} m, but the left to right reference points distance is {Math.round(referenceDistance)} m</div>
    console.log(`Warning: The displayed object is ${Math.round(metadata.actual_length_m)} m, but the left to right reference points distance is ${Math.round(referenceDistance)} m`);
  } else {
    // warning = <div>The displayed object is {Math.round(metadata.actual_length_m)} m, left to right reference points distance is {Math.round(referenceDistance)} m</div>;
  }

  const levelItems = {};

  const getSeriesName = (id) => {
    let name = "Unknown";
    series.map(i=>{if(i.id === id) name = i.title;} );
    return name;
  }

  return <div key={"sideways-"+height+"-"+width+(popped?popped.id:"")} style={{ position: "fixed", top: imageTop + "px", left: "0pt", width: "100%" }} onClick={()=>setPopped(null)}>
    <img key={"image-"+height+"-"+width} width="100%" src={getApi() + `/sidewaysimage?token=${ec(window.localStorage.getItem('token'))}`} alt="Side view" />
    <div key={"items-"+height+"-"+width} style={{ fontSize: smallFontSize, align: "left" }}>{series && series.map((item) => generateItem(metadata, item, imageScale, imageTop, setPopped, levelItems, width, height, selected, setSelected, maxAge))}</div>
        {popped ? <Popped key={"popped"+height+popped.id+popped.checkinTime+popped.alarmTime} 
                          popped={popped} setPopped={setPopped} markerSize={imageScale*metadata.marker_size} 
                          width={width} height={height} 
                          setSelectedId={setSelected} 
                          isChecker={isChecker} isAdmin={isAdmin}
                          setUncheckItem={setUncheckItem} uncheckItem={uncheckItem}
                          setCheckItem={setCheckItem} checkItem={checkItem}
                          setCurrentCheckpoint={setCurrentCheckpoint} currentCheckpoint={currentCheckpoint}></Popped> : null}
    <div key="warning" style={{position:"fixed", bottom:"14vh", left:"1vh", fontSize:"2vmin", color:"#000"}}>{warning}</div>
    {uncheckItem ? <Fetch key={"fetch-uncheck-"+uncheckItem} 
      submit={true} 
      onError={(e)=>setUncheckItem(null)}
      onResult={(r)=>{
        setUncheckItem(null);
      }} 
      api={getApi()+`/uncheck?token=${ec(window.localStorage.getItem('token'))}&device=${uncheckItem}`} /> : null }
    {checkItem && currentCheckpoint ? <Fetch key={"fetch-check-"+checkItem} 
      submit={true} 
      onError={(e)=>setCheckItem(null)}
      onResult={(r)=>{
        setCheckItem(null);
      }}
      api={getApi()+`/checkin?token=${ec(window.localStorage.getItem('token'))}&device=${ec(checkItem)}&checkpoint=${ec(currentCheckpoint)}`} /> : null}
    {checkItem && <div style={smallTextStyle}>Checking in {getSeriesName(checkItem)} to {getSeriesName(currentCheckpoint)}</div>}
    {checkItem && <div style={smallTextStyle}>Unchecking in {getSeriesName(uncheckItem)}</div>}
  </div>;
}
